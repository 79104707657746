import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Header } from 'components/header';
import { BaseLayout } from 'layouts/base-layout';
import styled from '@emotion/styled';
import Color from 'color';

const Wrapper = styled('div')`
  position: relative;
  height: 100%;
`;

const FixedHeader = styled(Header)`
  position: fixed;
  top: 0;
  z-index: 3000;

  width: 100%;

  background-color: ${Color('#001442')
    .alpha(0)
    .rgb()
    .string()};
`;

const Footer = styled('footer')`
  position: absolute;
  bottom: 0;

  padding-bottom: 1.5rem;
  margin-left: 1.5rem;
`;

export function TopHeader({ children }) {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <BaseLayout>
      <FixedHeader siteTitle={data.site.siteMetadata.title} />
      <Wrapper>
        {children}
        <Footer>© {new Date().getFullYear()}, Rugby World Titans</Footer>
      </Wrapper>
    </BaseLayout>
  );
}
