import { Link, graphql, useStaticQuery } from 'gatsby';
import styled from '@emotion/styled';
import React, { ReactElement } from 'react';
import Img from 'gatsby-image';

const StyledHeader = styled.header`
  padding: 0.5rem 1.5rem;

  background-color: #001442;
`;

interface Props {
  className?: string;
  siteTitle: string;
}

export function Header({ siteTitle, className }: Props): ReactElement<Props> {
  const data = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "rugby-world-titans-sm.png" }) {
        childImageSharp {
          fixed(height: 72) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const imageData =
    data && data.desktop && data.desktop.childImageSharp && data.desktop.childImageSharp.fixed
      ? data.desktop.childImageSharp.fixed
      : {};

  return (
    <StyledHeader className={className}>
      <Link
        to="/"
        style={{
          color: `white`,
          textDecoration: `none`
        }}
      >
        <Img fixed={imageData} alt={siteTitle} />
      </Link>
    </StyledHeader>
  );
}
