import React, { ReactNode, ReactElement } from 'react';
import { Global, css } from '@emotion/core';
import emotionNormalize from 'emotion-normalize';
import styled from '@emotion/styled';

interface Props {
  children: ReactNode | ReactNode[];
}

const Wrapper = styled('main')`
  height: 100%;
`;

export function BaseLayout({ children }: Props): ReactElement<Props> {
  return (
    <Wrapper>
      <Global
        styles={css`
          ${emotionNormalize}

          html {
            box-sizing: border-box;
            width: 100%;
            height: 100%;

            font-size: 100%;
            line-height: 1.5;
            -webkit-text-size-adjust: 100%;
          }

          *,
          *::before,
          *::after {
            box-sizing: inherit;
          }

          body {
            width: 100%;
            height: 100%;
            margin: 0;

            font-family: 'Fira Sans', sans-serif;
            font-size: 100%;
            font-weight: 300;
          }

          #___gatsby {
            height: 100%;
          }

          div[role='group'][tabindex] {
            height: 100%;
          }
        `}
      />
      {children}
    </Wrapper>
  );
}
